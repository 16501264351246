import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import metaImage from "../images/meta-image.png"

const NotFoundDiv = styled.div`
    padding: 250px 0;
    text-align: center;
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;
`;

const NotFoundTitle = styled.h1`
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;

    @media only screen and (min-width: 750px) {
        font-size: 60px;
        line-height: 60px;
    }
`;

const NotFoundContent = styled.p`
    display: block;
    margin-bottom: 30px;
`;

const NotFoundButton = styled.a`
    color: #FFFFFF;
    padding: 15px 30px;
    background-color: #4157FE;
    text-decoration: none;
    display: inline-block;
`;

const NotFoundPage = () => {
    return (
        <NotFoundDiv>
            <WrapperDiv>
                <SEO
                    title="404: Not found"
                    url="https://pointercreative.com/404"
                    image={`https://pointercreative.com${metaImage}`}
                />
                <NotFoundTitle>Not found</NotFoundTitle>
                <NotFoundContent>You just hit a route that doesn&#39;t exist... the sadness.</NotFoundContent>
                <NotFoundButton href="/">Back to home</NotFoundButton>
            </WrapperDiv>
        </NotFoundDiv>
    )
}

export default NotFoundPage